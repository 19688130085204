import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.button`
  border: 0;
  width: 120px;
  height: 32px;
  color: ${({ theme }) => theme.inverseColor};
  font-size: 1.5rem;
  border-radius: 5px;

  background: ${({ theme }) => theme.primary};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => transparentize(0.15, theme.primary)};
  }
  &:active {
    outline: 0;
    box-shadow: 0 0 0 0.2rem
      ${({ theme }) => transparentize(0.6, theme.primary)};
  }
`;
